import React from "react";
import LandingPage1 from "../../assets/PricingSection/LandingPage10.jpg";
import PrialtoUnit from "../../assets/PricingSection/middle.png";
import { Link } from "react-router-dom";

const SimplePricing = ({ toggleDarkMode, darkMode }) => {
  return (
    <div className="w-full bg-white dark:bg-[#000000]">
      <div className="w-[88%] mx-auto">
        {/* Pricing Section Heading */}
        <section
          className={`py-8 md:py-16 flex flex-col md:flex-row dark:text-white items-start gap-10`}
        >
          <div className="w-full md:w-1/2 space-y-6 text-left">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
              Flexible Pricing That Grows with Your Business
            </h1>
            <p className="text-lg leading-relaxed dark:text-gray-300">
              Our pricing is straightforward. We provide our services in bundles, offering a flat monthly rate with no hidden charges or extra fees. Each plan includes a dedicated assistant, backup support, and continuous guidance from your Engagement Manager to ensure you get the best results from our services.
            </p>
            <p className="text-lg leading-relaxed dark:text-gray-300">
              Need additional support? Our services are scalable to fit your needs. You can easily add more bundles whenever required.
            </p>
          </div>

          <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img
              src={LandingPage1}
              alt="Customer Service Representative"
              className="rounded-lg"
            />
          </div>
        </section>

        <div className="mt-12">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6 text-gray-900 dark:text-white">
              What's Included in a V Assist Pro Unit
            </h2>
          </div>
          <div className="flex flex-wrap justify-between items-center bg-[#f5f5f3] dark:bg-[#1c1c1c] shadow-lg p-8 rounded-lg">
            {/* Left Section */}
            <div className="w-full md:w-1/3 text-left">
              <h4 className="text-lg font-semibold text-[#17aee7] dark:text-[#34c7f3]">
                Dedicated Assistant
              </h4>
              <p className="mt-2 text-gray-700 dark:text-gray-300 md:w-[80%]">
                You'll have a close working relationship with your V Assist Pro assistant. Every assistant goes through a month-long training program before working with our members. We also provide ongoing training to ensure your assistant continuously upgrades their skills and deepens their knowledge of business practices and technology.
              </p>
              <h4 className="text-lg font-semibold mt-6 text-[#17aee7] dark:text-[#34c7f3]">
                132 Hours/Month
              </h4>
              <p className="mt-2 text-gray-700 dark:text-gray-300 md:w-[80%]">
                One "unit" comes with 132 hours of support per month and free episodic overflow time. Based on 15+ years of experience, we have found that 132 hours works well for most professionals.
              </p>
            </div>

            {/* Center Section with Image */}
            <div className="mb-7 md:mb-0">
              <div className="w-full justify-center flex items-center text-center py-4">
                <img
                  src={PrialtoUnit}
                  alt="V Assist Pro Unit Graphic"
                  className="mx-auto mb-4"
                  style={{ width: "240px", height: "auto" }}
                />
              </div>
              <h3 className="text-2xl font-bold dark:text-gray-100">
                {/* Removed the monthly pricing */}
              </h3>
            </div>

            {/* Right Section */}
            <div className="text-left w-full md:w-1/3">
              <h4 className="text-lg font-semibold text-[#17aee7] dark:text-[#34c7f3]">
                Engagement Manager
              </h4>
              <p className="mt-2 text-gray-700 dark:text-gray-300 md:w-[80%]">
                Your Engagement Manager (EM) ensures you get the most out of your V Assist Pro unit. They will work with you to create custom processes, troubleshoot issues, and act as your process design architect and delegation mentor.
              </p>
              <h4 className="text-lg font-semibold mt-6 text-[#17aee7] dark:text-[#34c7f3]">
                Backup Assistant
              </h4>
              <p className="mt-2 text-gray-700 dark:text-gray-300 md:w-[80%]">
                Our team-based approach means that you’ll always have multiple other teammates who know your processes and can access them in the system to take over where others left off.
              </p>
            </div>
          </div>
        </div>

        {/* Pricing Options */}
        <div className="mt-12">
          <div className="grid gap-8 md:grid-cols-3 text-left">
            <div className="bg-gray-100 dark:bg-[#1c1c1c] p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Individual
              </h3>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Talk to us for pricing and pilot design.
              </p>
            </div>
            <div className="bg-gray-100 dark:bg-[#1c1c1c] p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Teams
              </h3>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Talk to us for pricing and pilot design.
              </p>
            </div>
            <div className="bg-gray-100 dark:bg-[#1c1c1c] p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">
                Enterprise
              </h3>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Dedicated team of assistants with inter-departmental resource sharing. Organization-wide process design.
              </p>
              <p className="text-gray-700 dark:text-gray-300 mt-4">
                Talk to us for pricing and pilot design.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimplePricing;
