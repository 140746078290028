import React, { useEffect, useState, useRef } from "react";
import hero from "../assets/WhatWeDo/hero.svg"; // Adjust the path as necessary
import image from "../assets/NewComponent/proactive2.jpg";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faEnvelopeOpenText,
  faPhone,
  faBell,
  faCalendarAlt,
  faKeyboard,
  faPlane,
  faGlobe,
  faSearch,
  faUsers,
  faPen,
  faCalculator,
  faPalette,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import "./OurServices.css";

const servicesslides = [
  {
    title: "Dispatching Services",
    description: "Expert dispatching for Limousine and Chauffeur-driven car services.",
    icon: faCar,
  },
  {
    title: "Customer Support Solutions",
    description: "Handling emails and answering calls for Limousine/Chauffeur-driven car services, as well as other industries such as travel, logistics, and more.",
    icon: faPhone,
  },
  {
    title: "Driver Wake-Up Calls & Follow-Ups",
    description: "Night shift support, ensuring drivers are ready for their early morning rides.",
    icon: faBell,
  },
  {
    title: "Inbox & Calendar Management",
    description: "Keeping your communications and schedules organized across various industries.",
    icon: faEnvelopeOpenText,
  },
  {
    title: "Data Entry Services",
    description: "Accurate and timely data entry solutions for businesses in different sectors.",
    icon: faKeyboard,
  },
  {
    title: "Travel Booking",
    description: "Comprehensive travel arrangements and booking services.",
    icon: faPlane,
  },
  {
    title: "Website Design & Development",
    description: "Building and optimizing your online presence with professional websites.",
    icon: faGlobe,
  },
  {
    title: "Search Engine Optimization (SEO)",
    description: "Enhancing your website's visibility and search rankings.",
    icon: faSearch,
  },
  {
    title: "Social Media Management",
    description: "Managing and growing your brand's presence across social platforms.",
    icon: faUsers,
  },
  {
    title: "Content Writing",
    description: "Professional content creation for websites, blogs, and marketing materials.",
    icon: faPen,
  },
  {
    title: "Accounting & Bookkeeping",
    description: "Providing organized financial management services, including bookkeeping and accounting support.",
    icon: faCalculator,
  },
  {
    title: "Logo Design",
    description: "Creating unique and impactful logos to represent your brand.",
    icon: faPalette,
  },
  {
    title: "Power BI Dashboard Services",
    description: "Transforming your data into actionable insights through interactive and visually engaging Power BI dashboards.",
    icon: faChartLine,
  },
];

const WhatWeDoSection = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const detectTheme = () => {
      const currentTheme = document.documentElement.classList.contains("dark") ? "dark" : "light";
      setTheme(currentTheme);
    };

    detectTheme();
    const observer = new MutationObserver(detectTheme);
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["class"] });
    return () => observer.disconnect();
  }, []);

  const sliderRef = useRef(null);

  const handleNext = () => sliderRef.current.slickNext();
  const handlePrev = () => sliderRef.current.slickPrev();

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      { breakpoint: 640, settings: { slidesToShow: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 1280, settings: { slidesToShow: 3 } },
    ],
  };

  return (
    <div className="bg-white dark:bg-[#000000] dark:text-white">
      <section className="w-full max-w-screen-xl mx-auto px-4 py-8 sm:py-12 lg:py-16">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6 text-center">What We Do?</h2>
        <div className="flex flex-col sm:flex-row items-start sm:items-stretch sm:justify-between">
          <div className="sm:w-2/5 w-full mb-8 lg:mb-0 text-center sm:text-left">
            <img src={image} alt="What We Do" className="w-full mx-auto rounded-lg h-auto" />
          </div>
          <div className="sm:w-[55%] w-full">
            <Slider ref={sliderRef} {...settings}>
              {servicesslides.map((service, index) => (
                <div key={index} className="p-2">
                  <div className="bg-gray-50 dark:bg-[#151515] rounded-lg shadow-lg p-4 flex flex-col justify-between h-full">
                    <FontAwesomeIcon icon={service.icon} className="text-2xl mb-4" />
                    <h3 className="text-lg font-semibold dark:text-white">{service.title}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{service.description}</p>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="flex justify-center mt-4 space-x-4">
              <button onClick={handlePrev} className="slick-arrow slick-prev">Prev</button>
              <button onClick={handleNext} className="slick-arrow slick-next">Next</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatWeDoSection;
