import React, { useEffect, useState } from 'react';

const TextComponent = () => {
  const [theme, setTheme] = useState('light');

  return (
    <section className="py-14 sm:py-16 lg:py-20 bg-gray-100 dark:bg-[#151515] dark:text-white">
      <div className="w-[88%] mx-auto text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-light">
        <p className="leading-[60px]">
          <span className="font-medium text-[#17aee7] dark:text-[#17aee7]">V ASSIST PRO isn’t just a virtual assistant service provider—we’re your productivity partners.</span> 
          <span className="font-extralight">
          Think of us as your personal task-tamers, dedicated to keeping your day running effortlessly.
Join the elite circle and experience a new level of seamless support.
          </span>
        </p>

      </div>
    </section>
  );
};

export default TextComponent;
