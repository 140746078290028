import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "Why choose V Assist Pro?",
      answer:
        "With over a decade of experience and a proven track record in supporting executives, we offer flexible services that adapt to your evolving needs, ensuring you receive the support you need. Our skilled assistants are dedicated to helping your business succeed.",
    },
    {
      question: "Can I customize the tasks assigned to my assistant?",
      answer:
        "Absolutely! Our assistants are capable of handling a variety of tasks, including scheduling, email management, research, project coordination, and more. You can tailor their responsibilities to fit your specific requirements.",
    },
    {
      question: "What qualifications do your assistants possess?",
      answer:
        "Our assistants are highly skilled professionals with significant experience in executive support, and they undergo extensive training to uphold our high service standards.",
    },
    {
      question:
        "Do you offer any additional services beyond executive support?",
      answer:
        "While our primary focus is on executive support, we can also assist with a range of other services, including event planning, social media management, basic IT support, website design and development, merchandise design, email marketing, and SEO. Please let us know your specific needs, and we can tailor our services accordingly.",
    },
    {
      question: "What industries do your assistants specialize in?",
      answer:
        "Our services are not limited to any specific industry; we cater to a diverse array of sectors, including limousine and chauffeured car services, event planning, travel and hospitality, the entertainment industry, luxury concierge services, corporate services, real estate, personal services, and sports management.",
    },
    {
      question: "Are your services available to businesses outside the U.S.?",
      answer:
        "Yes, our services are also available to international clients. We can accommodate different time zones and provide global support.",
    },
    {
      question:
        "How do you ensure the security and confidentiality of my information?",
      answer:
        "We prioritize data security by employing our assistants in secure facilities where they use protected servers, networks, and company PCs.Additionally, all employees undergo comprehensive background checks and sign confidentiality agreements to ensure your information remains protected.",
    },
    {
      question: "Can I request assistance for personal tasks as well?",
      answer:
        "Yes, our assistants can help with both professional and personal tasks, such as managing personal schedules, travel arrangements, emails, and calendars.",
    },
    {
      question:
        "Do you offer support for specific projects or only ongoing tasks?",
      answer:
        "We offer support for both ongoing tasks and specific projects. Whether you need help with a one-time project or continuous support, we can tailor our services to meet your needs.",
    },
    {
      question:
        "What happens if my assistant is unavailable due to illness or vacation?",
      answer:
        "We have a team of skilled assistants, so if your assigned assistant is unavailable, we will provide a backup assistant to ensure uninterrupted support for your business.",
    },
    {
      question: "How is the performance of the assistants evaluated?",
      answer:
        "We regularly evaluate our assistants' performance based on client feedback, task completion, and overall efficiency. We strive to maintain the highest standards of service quality.",
    },
    {
      question: "How can I communicate with my assistant?",
      answer:
        "You can reach your assistant through text, email, or phone. We facilitate seamless communication to maintain a smooth and efficient workflow.",
    },
    {
      question: "What if I require assistance beyond the allocated hours?",
      answer:
        "If you need extra support, you can easily purchase additional hours, ensuring you have the help you need.",
    },
    {
      question: "How do I get started with V Assist Pro?",
      answer:
        "Once you contact us and we understand your needs, we start the onboarding process within a few days. We aim for a quick and smooth transition to ensure you get the support you need as soon as possible.",
    },
    {
      question: "Can your assistants help with international tasks?",
      answer:
        "Yes, our assistants are equipped to handle international tasks, including managing international travel, coordinating with global teams, and understanding different time zones.",
    },
    {
      question: "What if my needs change over time?",
      answer:
        "We understand that your business needs may change. Our services are flexible, and we can adjust the support hours or reassign tasks as your requirements evolve.",
    },
    {
      question:
        "Can I get a detailed breakdown of the tasks my assistant handles?",
      answer:
        "Yes, we provide detailed reports outlining the tasks completed by your assistant. This helps you track progress and understand the value provided by our service.",
    },
  ];

  return (
    <div className="w-full bg-white dark:bg-[#000000] py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100 mb-8">
          FAQ's
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`rounded-lg ${index === 0 ? "rounded-t-2xl" : ""} ${
                index === faqs.length - 1 ? "rounded-b-2xl" : ""
              } `}
            >
              <div
                className="flex items-center p-4 gap-x-4 cursor-pointer bg-[#f6f5f3] dark:bg-gray-800"
                onClick={() => toggleFAQ(index)}
              >
                <span
                  className={`text-gray-900 dark:text-gray-100 text-xl transform transition-transform duration-300 ${
                    activeIndex === index ? "rotate-180" : "rotate-0"
                  }`}
                >
                  {activeIndex === index ? (
                    <FaMinus className="text-[#17aee7]" />
                  ) : (
                    <FaPlus className="text-[#17aee7]" />
                  )}
                </span>
                <h4 className="md:text-xl text-left font-bold text-gray-900 dark:text-gray-100">
                  {faq.question}
                </h4>
              </div>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeIndex === index
                    ? "max-h-96 opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <div className="bg-[#f6f5f3]">
                  <div className="p-4 text-left ml-9 dark:bg-gray-900 text-gray-700 dark:text-gray-300">
                    <span>{faq.answer}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
