import React, { useEffect, useState } from "react";
import firstimg from "../assets/NewComponent/proactive1.jpg";
import secondimg from "../assets/NewComponent/proactive2.jpg";
import thirdimg from "../assets/NewComponent/proactive3.jpg";

const NewComponent = () => {
  return (
    <div className="bg-white dark:bg-[#000000] text-gray-900 dark:text-white">
      <div className="py-16 w-[88%] mx-auto ">
        {/* First Section */}
        <section className="flex flex-col lg:flex-row justify-between items-center text-left py-10 pb-24 gap-10">
          {/* Left Text Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <p className="text-lg text-teal-600 dark:text-teal-400">
              Dynamic Efficiency:
            </p>
            <h2 className="text-[38px] font-black mt-2 mb-4">
              Boost Your Productivity
            </h2>
            <p className="text-lg mb-6">
              At V Assist Pro, we expertly analyze your workflow and leverage
              our extensive collection of proven solutions to identify tasks we
              can handle on your behalf. You’ll be paired with an Engagement
              Manager, who will serve as your productivity strategist, guiding
              you in delegating responsibilities efficiently. Together with your
              assistant, they will help streamline your operations, making every
              interaction with us a powerful productivity booster that enables
              you to focus on what matters most.
            </p>
          </div>
          {/* Right Image Section with floating elements */}
          <div className="lg:w-1/2 relative flex justify-center items-center">
            {/* Placeholder Image */}
            <div className="flex gap-10">
              <img
                src={firstimg}
                alt="Main person placeholder"
                className={`w-full h-full object-cover transition-transform duration-500 ease-out hover:scale-105 hover:rotate-1 `}
                style={{ borderRadius: "25px" }}
              />
            </div>
          </div>
        </section>

        {/* second section */}
        <section className="flex flex-col lg:flex-row-reverse justify-between items-center text-left py-10 pb-24 gap-8">
          {/* Left Text Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <p className="text-lg text-teal-600 dark:text-teal-400">
              Expertise Delivered:
            </p>
            <h2 className="text-[38px] font-black mt-2 mb-4">
              Management and Training Handled for You
            </h2>
            <p className="text-lg mb-6">
              Each V Assist Pro Assistant undergoes weeks of rigorous training
              before being assigned to you, building on their existing knowledge
              and expertise. Our assistants have continuous access to a wealth
              of resources, experienced team members, and established processes.
            </p>
          </div>
          {/* Right Image Section with floating elements */}
          <div className="lg:w-1/2 relative flex justify-center items-center">
            {/* Placeholder Image */}
            <div className="">
              <img
                src={thirdimg}
                alt="Main person placeholder"
                className={`w-full h-full object-cover transition-transform duration-500 ease-out hover:scale-105 hover:rotate-1 `}
                style={{ borderRadius: "25px" }}
              />
            </div>
          </div>
        </section>

        {/* third section */}
        <section className="flex flex-col lg:flex-row justify-between items-center text-left py-10 pb-24 gap-8">
          {/* Left Text Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <p className="text-lg text-teal-600 dark:text-teal-400">
              Continuity Ensured:
            </p>
            <h2 className="text-[33px] font-normal mt-2 mb-4">
              Smooth Transitions with
              <span className="font-bold">
                V ASSIST PRO
              </span>
            </h2>
            <p className="text-lg mb-6">
              We cross-train our team on your tasks, ensuring all work is
              well-documented and easily transferable. Should your assistant
              take a leave or get promoted, a fully trained backup assistant can
              step in, guaranteeing that your service remains uninterrupted. Our
              management team oversees the transition, so you can focus on
              growing your business.
            </p>
          </div>
          {/* Right Image Section with floating elements */}
          <div className="lg:w-1/2 relative flex justify-center items-center">
            {/* Placeholder Image */}
            <div className="">
              <img
                src={secondimg}
                alt="Main person placeholder"
                className={`w-full h-full object-cover transition-transform duration-500 ease-out hover:scale-105 hover:rotate-1 `}
                style={{ borderRadius: "25px" }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NewComponent;
