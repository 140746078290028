import React, { useEffect, useRef } from "react";
import { FaArrowDown } from "react-icons/fa"; // Using react-icons for the arrow icon
import "./HeroSection.css"; // Import the CSS file

const HeroSection = () => {
  const videoRef = useRef(null);
  const videos = [
    require("../assets/ForHeroSection/finalbg.mp4"), // Adjust the path as necessary
    // require("../assets/ForHeroSection/0925.mp4"), // Adjust the path as necessary
    // require("../assets/ForHeroSection/herovideo.mp4"), // Adjust the path as necessary
  ];
  let videoIndex = 0;

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleVideoEnd = () => {
      videoIndex = (videoIndex + 1) % videos.length;
      videoElement.src = videos[videoIndex];
      videoElement.play();
    };

    videoElement.addEventListener("ended", handleVideoEnd);
    return () => {
      videoElement.removeEventListener("ended", handleVideoEnd);
    };
  }, [videos]);

  return (
    <section className="z-10 hero-section w-full h-screen lg:min-h-screen flex relative">
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={videos[0]}
        autoPlay
        muted
        loop={false}
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="text-start px-6 md:px-20 pt-60 text-white relative z-10">
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-5xl font-bold">
          {/* Welcome Aboard! */}
          At V Assist Pro,
        </h1>
        <p className="text-2xl md:text-1xl lg:text-2xl xl:text-3xl mt-4">
          {/* LET'S GET STARTED! */}
          we believe being smart goes beyond intelligence. It’s about making the right decisions at the right time, and our team excels at both.        </p>
      </div>
      <div className="scroll-arrow">
        <div className="dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <div className="arrow-container">
          <FaArrowDown className="arrow-icon" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
