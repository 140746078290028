import React from "react";
import Layout from "./Layout";
import "./WhyChooseDetailPage.css";
import ProductivityPath from "./ProductivityPath";
import SecondFooter from "./SecondFooter";
import image from "../assets/NewComponent/proactive5.jpg";
import tabletopicon from "../assets/WhyUsDetailPage/table-top-icon.webp";
import tablebottomicon from "../assets/WhyUsDetailPage/table-bottom-icon.webp";
import { Link } from "react-router-dom";

const WhyChooseDetailPage = ({ toggleDarkMode, darkMode }) => {
  const features = [
    {
      feature: "Specialized Knowledge",
      vAssistPro: "Offers over 16 years of refined best practices.",
      agencies: "Experience levels vary across agencies.",
      inHouse: "Varies based on the individual and company.",
    },
    {
      feature: "Operational Oversight",
      vAssistPro:
        "Implements internal management with quality assurance systems.",
      agencies: "Minimal oversight typically provided.",
      inHouse: "Lacks formal performance management.",
    },
    {
      feature: "Back-up Support",
      vAssistPro:
        "Ensures fully-trained primary and secondary assistants.",
      agencies: "Available only if multiple assistants are hired.",
      inHouse: "Backup is possible only with a larger team.",
    },
    {
      feature: "Pricing Structure",
      vAssistPro: "Offers a simple, flat-rate pricing model.",
      agencies: "Often charged hourly, rates depend on specific roles.",
      inHouse:
        "Includes costs for management, equipment, training, salary, and benefits.",
    },
    {
      feature: "Data Protection",
      vAssistPro: "Provides comprehensive information security.",
      agencies: "Security measures vary across providers.",
      inHouse: "Security protocols depend on company practices.",
    },
    {
      feature: "Initial Consultation",
      vAssistPro: "Free consultation for first-time clients.",
      agencies: "Varies by provider.",
      inHouse: "Dependent on internal processes.",
    },
    {
      feature: "Availability",
      vAssistPro: "24/7 support available.",
      agencies: "Support hours differ between providers.",
      inHouse:
        "Availability varies according to company structure and staff size.",
    },
  ];

  return (
    <Layout toggleDarkMode={toggleDarkMode} darkMode={darkMode}>
      <div className="bg-white dark:bg-[#151515] text-gray-900 dark:text-white pb-16">
        <section className="py-8 md:py-16 w-[88%] mx-auto">
          {/* Upper first section */}
          <section className="py-8 md:pt-16 md:pb-40 flex flex-col md:flex-row dark:text-white gap-10">
            <div className="w-full md:w-1/2 space-y-6 text-left">
              <h1 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                Why Customers Choose <br />
                <span className="font-bold">
                  V Assist Pro Over Alternatives
                </span>
              </h1>
              <p className="text-base md:text-lg leading-relaxed">
                V Assist Pro stands out from other virtual assistant services by
                providing proactive, managed support. Every assistant is backed
                by an Engagement Manager, a dedicated backup team, and robust
                management resources, ensuring they are fully onboarded and
                ready to integrate into your workflow from day one. We offer
                enterprise-level security and simple, flat-rate pricing, making
                us the trusted partner for businesses looking to maximize
                efficiency and achieve greater success. Ready to experience our
                tailored support? Book a call with us today!
              </p>
              <div className="mt-5">
                <Link
                  to={"/contact-us"}
                  className="font-semibold text-base md:text-lg no-underline"
                >
                  Ready for proactive support?{" "}
                  <span className="text-[#1392b5] hover:underline">
                    Book a Call.
                  </span>
                </Link>
              </div>
            </div>

            <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center">
              <img
                src={image}
                alt="Customer Service Representative"
                className="rounded-lg w-full h-auto"
              />
            </div>
          </section>

          {/* Section Heading */}
          <h2 className="text-center text-xl md:text-2xl lg:text-3xl font-semibold">
            How V Assist Pro Stands Out from the Competition
          </h2>

          {/* Responsive Table Section */}
          <div className="relative rounded-lg py-8 md:py-16">
            <div className="hidden lg:block">
              {/* Desktop Table */}
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="bg-teal-500 text-white font-bold text-lg p-6 rounded-tl-2xl">
                      Features
                    </th>
                    <th
                      className="bg-gray-800 text-white font-bold text-lg p-6 relative"
                    >
                      V Assist Pro
                      {/* Top-left icon for the first row */}
                      <img
                        src={tabletopicon}
                        alt="Top Icon"
                        className="absolute -top-5 -left-5 w-12 h-12"
                      />
                    </th>
                    <th className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white font-bold text-lg p-6">
                      Virtual Assistant Agencies
                    </th>
                    <th className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white font-bold text-lg p-6 rounded-tr-2xl">
                      In-House
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {features.map((row, index) => (
                    <tr key={index}>
                      <td
                        className={`bg-teal-500 text-white font-semibold text-base p-6 ${
                          index === features.length - 1
                            ? "rounded-bl-2xl"
                            : ""
                        }`}
                      >
                        {row.feature}
                      </td>
                      <td
                        className={`bg-gray-800 text-white p-6 relative ${
                          index === features.length - 1 ? "rounded-bl-2xl" : ""
                        }`}
                      >
                        {row.vAssistPro}
                        {/* Bottom-right icon for the last row */}
                        {index === features.length - 1 && (
                          <img
                            src={tablebottomicon}
                            alt="Bottom Icon"
                            className="absolute -bottom-5 -right-5 w-12 h-12"
                          />
                        )}
                      </td>
                      <td className="bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white p-6">
                        {row.agencies}
                      </td>
                      <td
                        className={`bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white p-6 ${
                          index === features.length - 1
                            ? "rounded-br-2xl"
                            : ""
                        }`}
                      >
                        {row.inHouse}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Mobile/Tablet View */}
            <div className="lg:hidden">
              {features.map((row, index) => (
                <div
                  key={index}
                  className="mb-6 border rounded-lg overflow-hidden"
                >
                  <div className="bg-teal-500 text-white font-semibold text-base p-4">
                    {row.feature}
                  </div>
                  <div className="p-4 bg-gray-800 text-white">
                    <div className="font-bold">V Assist Pro</div>
                    <div>{row.vAssistPro}</div>
                  </div>
                  <div className="p-4 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white">
                    <div className="font-bold">Virtual Assistant Agencies</div>
                    <div>{row.agencies}</div>
                  </div>
                  <div className="p-4 bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white">
                    <div className="font-bold">In-House</div>
                    <div>{row.inHouse}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <ProductivityPath
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
        />
        <SecondFooter
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          headingText={"Ready to Discuss Your Admin Needs?"}
        />
      </div>
    </Layout>
  );
};

export default WhyChooseDetailPage;
